<template>
  <div class="addUs">
    <div class="banner">
      <img src="../../assets/addUsbanner.png" alt="" />
    </div>
    <div class="content">
      <b-container>
        <div class="icons">
          <div
            class="item"
            v-for="(iconItem, iconIndex) in icons"
            :key="iconIndex"
          >
            <div class="imgs">
              <img :src="iconItem.pic" alt="" />
            </div>
            <p>{{ iconItem.title }}</p>
            <span>{{ iconItem.text }}</span>
          </div>
        </div>
        <div class="email">
          <h1>迎接新的你</h1>
          <h3>投递邮箱：hr@gz-mengku.com</h3>
        </div>
        <div class="job">
          <div
            class="jobItem"
            v-for="(jobItem, jobIndex) in jobs"
            :key="jobIndex + 'a'"
          >
            <h2>{{ jobItem.title }}</h2>
            <p>{{ jobItem.subTitle }}</p>
            <h3>{{ jobItem.nextTitle }}</h3>
            <div class="jobItems">
              <p
                v-for="(jobOneItem, jobOneIndex) in jobItem.list"
                :key="jobOneIndex + 'b'"
              >
                {{ jobOneItem.ctitle }}
              </p>
            </div>
            <h3>{{ jobItem.contentTitle }}</h3>
            <div class="jobItems">
              <p
                v-for="(jobTwoItem, jobTwoIndex) in jobItem.twoList"
                :key="jobTwoIndex + 'c'"
              >
                {{ jobTwoItem.ctitle }}
              </p>
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: [
        {
          pic: require("@/assets/logo_joinus001.png"),
          title: "萌酷生活",
          text: "为生活赋予更多美好",
        },
        {
          pic: require("@/assets/logo_joinus003.png"),
          title: "迎接特别",
          text: "自由，包容，共同发展",
        },
        {
          pic: require("@/assets/logo_joinus002.png"),
          title: "项目积累",
          text: "稳定，积累，发展",
        },
      ],
      jobs: [
        {
          title: "后端开发工程师",
          subTitle: "广州 | 2年| 本科",
          nextTitle: "工作内容",
          contentTitle: "工作要求",
          list: [
            {
              ctitle:
                "1.进行系统业务的分析、系统架构设计，代码重构及性能优化等工作；",
            },
            {
              ctitle: "2.指导项目组其成员进行业务功能开发、检查其开发质量",
            },
            {
              ctitle:
                "3.系统支持：进行问题的定位、维护和优化，支持配合其他团队的开发工作；",
            },
            {
              ctitle:
                "4.参与技术攻关，解决生产及项目中各种技术难题，进行方案选型；",
            },
            {
              ctitle: "5.按时完成领导交办的其他开发及研究工作。",
            },
          ],
          twoList: [
            {
              ctitle: "1.大专3年以上WEB前端开发经验；",
            },
            {
              ctitle:
                "2.精通 HTMLCSSJS等前端技术，深入理解W3C web标准，能手写符合W3C标准、兼容多种浏览器的前端页面代码；",
            },
            {
              ctitle:
                "3.熟练掌握VueJS，在实际项目中使用过Element UI、Mint UI 或者Vant UI；",
            },
            {
              ctitle:
                "4.熟悉前后端分离开发模式，有实际的前后端分离的开发经验；",
            },
            {
              ctitle: "5.对web产品设计和用户体验有一定了解；",
            },
            {
              ctitle: "6.熟练使用nodejs、npm、webpack、svn等工具；",
            },
            {
              ctitle:
                "7.有较强的团队精神、沟通能力、逻辑思维能力，及较强的主动性与责任心。",
            },
          ],
        },
        {
          title: "Java中级开发工程师",
          subTitle: "广州 | 2年| 本科",
          nextTitle: "工作内容",
          contentTitle: "工作要求",
          list: [
            {
              ctitle:
                "1.进行系统业务的分析、系统架构设计，代码重构及性能优化等工作;",
            },
            {
              ctitle: "指导项目组其成员进行业务功能开发、检查其开发质量;",
            },
            {
              ctitle:
                "3.系统支持：进行问题的定位、维护和优化，支持配合其他团队的开发工作；",
            },
            {
              ctitle:
                "4.参与技术攻关，解决生产及项目中各种技术难题，进行方案选型；",
            },
            {
              ctitle: "5.按时完成领导交办的其他开发及研究工作。",
            },
          ],
          twoList: [
            {
              ctitle: "1.计算机相关专业；",
            },
            {
              ctitle:
                "2.精通Java开发，熟悉Java的核心语法，了解J2EE设计模式，熟悉面向对象的分析设计和工具;",
            },
            {
              ctitle:
                "3.熟悉掌握SQL语言，能够熟练进行数据库设计，有Oracle、Postgresql等相关数据库项目开发经验；",
            },
            {
              ctitle:
                "4.熟悉分布式系统的设计和应用，熟悉分布式、缓存、消息等机制，能对分布式常用技术进行合理应用；",
            },
            {
              ctitle:
                "5.掌握多线程及高性能的设计与编码及性能调优,熟悉微服务、熟悉Spring Cloud；",
            },
            {
              ctitle: "6.具备良好的代码编程习惯及较强的文档编写能力。",
            },
          ],
        },
        {
          title: "项目执行专员",
          subTitle: "广州 | 2年| 专科以上",
          nextTitle: "工作内容",
          contentTitle: "工作要求",
          list: [
            {
              ctitle:
                "1.挖掘所辖区域教育行业软件、课程建课项目机会，负责公司软件产品在高教市场的销售；管理客户关系，完成销售任务；",
            },
            {
              ctitle: "2.负责所辖区域软件项目销售并推进招投标；",
            },
            {
              ctitle:
                "3.负责区域重点客户的公关与维护，确保阶段性业绩指标的达成；",
            },
            {
              ctitle: "4.完成上级领导分配的指标及其他工作事项。",
            },
          ],
          twoList: [
            {
              ctitle: "1.专业不限、大专以上学历，2年以上行业大客户销售经验；",
            },
            {
              ctitle: "2.具有较强的客户挖掘能力和客户维系的能力；",
            },
            {
              ctitle: "3.具有良好的语言表达能力、抗压能力和团队协作能力；",
            },
            {
              ctitle: "4.具备教育（大中专院校）行业背景资源者优先考虑。",
            },
          ],
        },
        {
          title: "市场经理",
          subTitle: "广州 | 2年 | 专科以上",
          nextTitle: "工作内容",
          contentTitle: "工作要求",
          list: [
            {
              ctitle:
                "1.挖掘所辖区域教育行业软件、课程建课项目机会，管理客户关系，完成对应任务；",
            },
            {
              ctitle: "2.负责整个市场工作，为业绩负责，带领团队完成市场目标；",
            },
            {
              ctitle: "3.制定市场计划，并依据计划推进市场工作；",
            },
            {
              ctitle: "4.进行老客户的回访及信息的反馈工作。",
            },
          ],
          twoList: [
            {
              ctitle:
                "1.大专或以上学历，热爱教育行业，有教育行业行业产品营销经验优先；",
            },
            {
              ctitle:
                "2.精通Java开发，熟悉Java的核心语法，了解J2EE设计模式，熟悉面向对象的分析设计和工具善于与人交流，表达清晰，气质好，有较强的沟通及商务谈判能力；",
            },
            {
              ctitle:
                "3.良好的服务意识，能够接受富有挑战性的工作、抗压能力强。",
            },
          ],
        },
        {
          title: "Unity3d开发工程师",
          subTitle: "广州 | 2年 | 专科以上",
          nextTitle: "工作内容",
          contentTitle: "工作要求",
          list: [
            {
              ctitle:
                "1.使用Unity3D引擎开发VR，包括但不局限于核心结构搭建、前后端对接、多平台发布等；",
            },
            {
              ctitle:
                "2.负责Unity3D程序的设计、开发与优化，确保所负责模块的程序稳定性与性能优化；",
            },
            {
              ctitle: "3.根据项目需求对各类所需工具进行再开发及调整优化；",
            },
            {
              ctitle:
                "4.与项目组策划、美术保持良好沟通，确保开发需求的准确实现。",
            },
          ],
          twoList: [
            {
              ctitle: "1.有高品质成功上线的项目经验优先；",
            },
            {
              ctitle: "2.优秀的开发能力，扎实的算法和数学基础；",
            },
            {
              ctitle: "3.具备良好的分析和解决问题的能力以及较强的抗压能力；",
            },
            {
              ctitle: "4.熟悉C# 及lua编程语言。",
            },
            {
              ctitle:
                "5.掌握多线程及高性能的设计与编码及性能调优,熟悉微服务、熟悉Spring 丰富的Unity引擎实践经验，熟悉其框架和热更/资源管理等主要模块的功能特性，",
            },
            {
              ctitle:
                "6.对场景、UI、动画、特效、内存、drawcall、overdraw优化有线上产品经验者优先",
            },
            {
              ctitle: "7.精通客户端的性能优化",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}
.addUs {
  .banner {
    margin-top: 80px;
    width: 100%;
    img {
      width: 100%;
    }
  }
  .content {
    .icons {
      margin-top: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        text-align: center;
        .imgs {
          width: 100%;
          img {
            width: 50%;
          }
        }
        p {
          font-size: 20px;
          font-weight: 700;
          color: #333333;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .email {
      margin-top: 180px;
      h1 {
        font-weight: 700;
        color: #333333;
      }
      h3 {
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        padding-bottom: 24px;
        border-bottom: 1px solid #ebebeb;
      }
    }
    .job {
      margin-bottom: 200px;
      .jobItem {
        margin-top: 100px;
        color: #333333;
        h2 {
          font-size: 36px;
          font-weight: 700;
        }
        p {
          font-size: 20px;
          color: #333333;
          font-weight: 400;
        }
        h3 {
          margin-top: 72px;
          font-size: 26px;
          font-weight: 500;
        }
        .jobItems {
          margin-top: 15px;
          p {
            font-size: 20px;
            font-weight: 400;
            color: #666666;
            line-height: 36px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  p {
    margin-bottom: 0rem;
  }
  .addUs {
    .banner {
      margin-top: 60px;
    }
    .content {
      padding: 0 15px;
      .icons {
        margin-top: 30px;
        .item {
          .imgs {
            width: 100%;
            text-align: center;
            img {
              width: 20%;
            }
          }
          p {
            margin-top: 10px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #333333;
          }
          span {
            display: block;
            font-size: 8px;
            text-align: center;
            font-weight: 400;
          }
        }
      }
      .email {
        margin-top: 50px;
        h1 {
          font-size: 14px;
          font-weight: 500;
          color: #0c0c0c;
        }
        h3 {
          font-size: 12px;
          font-weight: 400;
          padding-bottom: 10px;
          border-bottom: 1px solid transparent;
          color: #0c0c0c;
        }
      }
      .job {
        margin-bottom: 35px;
        width: 100%;
        .jobItem {
          margin-top: 37px;
          padding: 15px;
          color: #333333;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0px 0px 10px 0px rgba(71, 189, 255, 0.1);
          h2 {
            font-size: 14px;
            font-weight: 500;
            position: relative;
            &::before {
              position: absolute;
              left: -5px;
              top: -5px;
              content: "";
              display: block;
              width: 16px;
              height: 16px;
              background: url("../../assets/addUsicon.png") no-repeat;
              background-size: cover;
            }
          }
          p {
            font-size: 12px;
            font-weight: 400;
            color: #999;
          }
          h3 {
            margin-top: 15px;
            font-size: 14px;
            font-weight: 500;
          }
          .jobItems {
            margin-top: 15px;
            width: 100%;
            p {
              font-size: 12px;
              font-weight: 400;
              color: #666666;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
</style>